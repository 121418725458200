document.addEventListener('turbolinks:load', function(){
  toggleButtonClicked()
})

// headerのトグルボタンが押されたとき、メニューが開閉
const toggleButtonClicked = function(){
  const toggleButton = document.getElementById('toggle-button');
  const headerNav = document.getElementById('header-nav');
  toggleButton.addEventListener('click', function(){
    if (headerNav.classList.contains("hidden")) {
      headerNav.classList.remove("hidden");
    }else{
      headerNav.classList.add("hidden")
    }
  })
}